/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './FlightFilter.scss';
import FlightButton from '../flight-button/FlightButton';

const DEFAULT_CLASS = 'flight-filter';

const FlightFilter = (props) => {
  const [isOpened, setIsOpened] = useState(false);
  const {
    className, children, isExpanded, clearAll, numFilters,
  } = props;

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += className ? ` ${className}` : '';

  useEffect(() => {
    setIsOpened(isExpanded);
  }, [isExpanded]);

  function toggleFilters() {
    setIsOpened(!isOpened);
  }

  return (
    <div className={wrapperClass}>
      <FlightButton
        className="toggle-btn"
        label={(
          <React.Fragment>
            Filters
            { (numFilters > 0) && <span className="filters-badge">{numFilters}</span> }
          </React.Fragment>
        )}
        theme="link"
        onClick={toggleFilters}
        iconRight={isOpened ? 'baselineKeyboardArrowUp' : 'baselineKeyboardArrowDown'}
        hasUnderline={false}
      />
      {(numFilters > 0) && (
        <FlightButton
          className="clear-all"
          label="Clear all"
          theme="link"
          size="small"
          onClick={clearAll}
          hasUnderline={false}
        />
      )}
      {isOpened && (
        <div className={`${DEFAULT_CLASS}__items`}>
          {children}
        </div>
      )}
    </div>
  );
};

FlightFilter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isExpanded: PropTypes.bool,
  clearAll: PropTypes.func,
  numFilters: PropTypes.number,
};

FlightFilter.defaultProps = {
  className: '',
  isExpanded: false,
  clearAll: () => undefined,
  numFilters: 0,
};

export default FlightFilter;
