/**
 * Generates an array of timestamps of a day with this schema:
 * [{
 * key: 0,
 * name: "12:00 AM",
 * value: 0
 * }, {
 * ...
 * }]
 * value is the number of seconds from 12:00 AM to current timestamp
 *
 * @param {number} interval  The interval between each timestamp of the day in minutes
 * @returns {Array<Object>}
 */
function timeIntervalGen(interval) {
  const times = [];
  let startTime = 0;
  const meridian = ['AM', 'PM'];
  const hoursCorrection = { '00': '12' };
  let count = 0;
  let epoch = 0;

  for (let i = 0; startTime < 24 * 60; i++) {
    const hoursOfDay = Math.floor(startTime / 60); // getting hours of day in 0-24 format
    const minutesOfHour = (startTime % 60); // getting minutes of the hour in 0-55 format

    // getting curr time
    const currHour = (`0${hoursOfDay % 12}`).slice(-2);
    const currMin = (`0${minutesOfHour}`).slice(-2);
    const currMeridian = meridian[Math.floor(hoursOfDay / 12)];

    // correcting 00 to 12 when necessary
    const updatedHour = hoursCorrection[currHour] || currHour;

    // adding timestamp to array
    times[i] = { key: count.toString(), name: `${updatedHour}:${currMin} ${currMeridian}`, value: epoch };

    // incrementing counters
    count += 1;
    epoch += (interval * 60);
    startTime += interval;
  }

  return times;
}

export default timeIntervalGen;
