import React from 'react';
import PropTypes from 'prop-types';
import getIcon from '../utils/getIcon';
import './FlightButton.scss';

const DEFAULT_CLASS = 'flight-button';
const BUTTON_THEMES = {
  primary: 'primary',
  secondary: 'secondary',
  minor: 'minor',
  link: 'link',
};
const BUTTON_SIZES = {
  small: 'small',
  medium: 'medium',
  large: 'large',
};

const FlightButton = (props) => {
  const {
    className, disabled, hasUnderline, iconLeft, iconRight,
    label, loading, size, theme, helperkey, onClick, onBlur,
    onFocus, onKeyDown, onMouseEnter, onMouseLeave, buttonRef,
    type, isPropagateUpperActions, ariaLabel,
  } = props;

  const handleOnClick = (event) => {
    if (disabled || loading) return;
    if (onClick) onClick(event);
    if (!isPropagateUpperActions) {
      event.stopPropagation();
    }
  };

  const handleOnBlur = (event) => {
    if (disabled || loading) return;
    if (onBlur) onBlur(event);
  };

  const handleOnFocus = (event) => {
    if (disabled || loading) return;
    if (onFocus) onFocus(event);
  };

  const handleOnKeyDown = (event) => {
    if (disabled || loading) return;
    if (onKeyDown) onKeyDown(event);
  };

  const handleOnMouseEnter = (event) => {
    if (disabled) return;
    if (onMouseEnter) onMouseEnter(event);
  };

  const handleOnMouseLeave = (event) => {
    if (disabled) return;
    if (onMouseLeave) onMouseLeave(event);
  };

  const labelElement = label ? (
    <span className={`${DEFAULT_CLASS}__label`}>
      {label}
    </span>
  ) : null;
  const loadingIcon = loading ? getIcon('loading') : null;

  let buttonClass = DEFAULT_CLASS;
  buttonClass += ` ${DEFAULT_CLASS}--${BUTTON_THEMES[theme]
    || BUTTON_THEMES.primary}`;
  buttonClass += (BUTTON_THEMES[theme] === BUTTON_THEMES.link
    && hasUnderline ? ` ${DEFAULT_CLASS}--link--has-underline`
    : '');
  buttonClass += ` ${DEFAULT_CLASS}--${BUTTON_SIZES[size]
    || BUTTON_SIZES.medium}`;
  buttonClass += (iconLeft || iconRight)
    ? ` ${DEFAULT_CLASS}--with-icon` : '';
  buttonClass += !label ? ` ${DEFAULT_CLASS}--without-text` : '';
  buttonClass += loading ? ` ${DEFAULT_CLASS}--loading` : '';
  buttonClass += className ? ` ${className}` : '';
  const btnProps = {
    className: buttonClass,
    disabled,
    onBlur: handleOnBlur,
    onClick: handleOnClick,
    onFocus: handleOnFocus,
    onKeyDown: handleOnKeyDown,
    onMouseEnter: handleOnMouseEnter,
    onMouseLeave: handleOnMouseLeave,
    type,
    ref: buttonRef,
  };
  if (helperkey) {
    btnProps.helperkey = helperkey;
  }
  if (ariaLabel) {
    btnProps['aria-label'] = ariaLabel;
  } else if (!label) {
    // throw error for accessibility
    console.error("Flight Design System: 'ariaLabel' needs to be present when 'label' is empty in FlightButton");
  }

  /* eslint-disable react/button-has-type */
  return (
    <button {...btnProps}>
      {iconLeft ? getIcon(iconLeft) : null}
      {labelElement}
      {iconRight ? getIcon(iconRight) : null}
      {loadingIcon}
    </button>
  );
};
/* eslint-enable react/button-has-type */
FlightButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  hasUnderline: PropTypes.bool,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  label: PropTypes.node,
  loading: PropTypes.bool,
  onBlur: PropTypes.func,
  onClick: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  size: PropTypes.string,
  theme: PropTypes.string,
  type: PropTypes.string,
  helperkey: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  buttonRef: PropTypes.shape({
    current: PropTypes.shape({
      focus: PropTypes.func,
    }),
  }),
  isPropagateUpperActions: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

FlightButton.defaultProps = {
  className: '',
  disabled: false,
  hasUnderline: true,
  iconLeft: '',
  iconRight: '',
  label: '',
  loading: false,
  onBlur: () => undefined,
  onFocus: () => undefined,
  onKeyDown: () => undefined,
  onMouseEnter: () => undefined,
  onMouseLeave: () => undefined,
  size: 'medium',
  theme: 'primary',
  type: 'button',
  helperkey: '',
  buttonRef: null,
  isPropagateUpperActions: false,
  ariaLabel: '',
};

export default FlightButton;
