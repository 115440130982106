/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { CALENDAR_TABLE_HEADERS, EVENT_KEYS, MONTHS } from '../constants';
import getDateString from '../utils/getDateString';
import getMonthDaysOfDate from '../utils/getMonthDaysOfDate';
import FlightButton from '../flight-button/FlightButton';
import FlightTextInput from '../flight-text-input/FlightTextInput';
import FlightDropdown from '../flight-dropdown/FlightDropdown';
import FlightTable from '../flight-table/FlightTable';
import './FlightDatePicker.scss';

const INVALID_DATE_MESSAGE = 'Invalid date entered';
const MIN_DATE_ERROR = 'Selected date is before minimum date';
const MAX_DATE_ERROR = 'Selected date is after maximum date';
const DEFAULT_CLASS = 'flight-date-picker';
const DROPDOWN_CLASS = `${DEFAULT_CLASS}__dropdown`;

const FlightDatePicker = (props) => {
  const {
    className, selected, dropdownDirection, onChange,
    onFocus, onSelect, disabled, hasError, errorMessage,
    label, maxDate, minDate, autoFocus,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [isValidDate, setIsValidDate] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const today = new Date();
  if (maxDate) maxDate.setHours(0, 0, 0, 0);
  if (minDate) minDate.setHours(0, 0, 0, 0);

  const firstDayOfCurrentMonth = new Date(
    currentDate.getFullYear(), currentDate.getMonth(), 1,
  );
  const lastDayOfCurrentMonth = new Date(
    currentDate.getFullYear(), currentDate.getMonth() + 1, 0,
  );

  const validateDate = (date) => {
    if (!date || Number.isNaN(new Date(date).getTime())) {
      setIsValidDate(false);
      return false;
    }

    setIsValidDate(true);
    return true;
  };

  useEffect(() => {
    if (selected && validateDate(selected)) {
      setInputValue(getDateString(selected));
    } else if (selected) {
      setInputValue('Invalid Date');
    }
  }, [selected]);

  useEffect(() => {
    if (isOpen) {
      setCurrentDate(selected && validateDate(selected)
        ? new Date(selected) : new Date());
    }
  }, [selected, isOpen]);

  const handleClose = (isResetInputToSelect) => {
    setIsOpen(false);

    if (isResetInputToSelect && validateDate(selected)) {
      setInputValue(getDateString(selected));
    }
  };

  const handleTextOnChange = (event) => {
    setInputValue(event.target.value);
    validateDate(event.target.value);
    if (!isOpen) setIsOpen(true);
    if (onChange) onChange(event);
  };

  const handleInputOnFocus = (event) => {
    if (disabled) return;
    setIsOpen(true);
    if (event.target.value.length) {
      event.target.select();
    }
    if (onFocus) onFocus(event);
  };

  const handleInputOnKeyDown = (event) => {
    if (disabled) return;
    const inputDateValue = new Date(event.target.value);
    if (event.key === EVENT_KEYS.ENTER) {
      if (!Number.isNaN(inputDateValue.getTime())) {
        inputDateValue.setHours(0, 0, 0, 0);

        if ((minDate && minDate.getTime() > inputDateValue.getTime())
        || (maxDate && maxDate.getTime() < inputDateValue.getTime())) {
          setIsValidDate(false);
          return;
        }
      }

      onSelect(inputDateValue);
      setIsValidDate(true);
      setIsOpen(!isOpen);
    } else if (event.key === EVENT_KEYS.ESCAPE) {
      handleClose(true);
    }
  };

  const handleCalenderPickDate = (dateNumber) => {
    if (disabled) return;
    const selectDate = new Date(currentDate);
    selectDate.setDate(dateNumber);
    selectDate.setHours(0, 0, 0, 0);
    onSelect(selectDate);
    handleClose(false);
    setIsValidDate(true);
  };

  const toggleMonth = (isPrevious) => {
    const nextCurrentDate = new Date(currentDate);
    nextCurrentDate.setMonth(currentDate.getMonth() + (isPrevious ? -1 : 1));
    setCurrentDate(nextCurrentDate);
  };

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += disabled ? ` ${DEFAULT_CLASS}--disabled` : '';
  wrapperClass += className ? ` ${className}` : '';

  let isSelectedOutsideMinMax = false;
  let inputErrorMessage = '';
  if (minDate && selected && minDate.getTime() > selected.getTime()) {
    isSelectedOutsideMinMax = true;
    inputErrorMessage = MIN_DATE_ERROR;
  } else if (maxDate && selected && maxDate.getTime() < selected.getTime()) {
    isSelectedOutsideMinMax = true;
    inputErrorMessage = MAX_DATE_ERROR;
  }
  if (hasError) {
    inputErrorMessage = errorMessage;
  } else if (!isValidDate) {
    inputErrorMessage = INVALID_DATE_MESSAGE;
  }

  return (
    <span className={wrapperClass}>
      <FlightDropdown
        className={DROPDOWN_CLASS}
        trigger={(
          <FlightTextInput
            className={`${DROPDOWN_CLASS}__input`}
            value={inputValue}
            label={label}
            isLabelAlwaysOn={false}
            onChange={handleTextOnChange}
            onFocus={handleInputOnFocus}
            onKeyDown={handleInputOnKeyDown}
            inputRef={inputRef}
            disabled={disabled}
            hasError={hasError || !isValidDate || isSelectedOutsideMinMax}
            errorMessage={inputErrorMessage}
            autoFocus={autoFocus}
          />
        )}
        direction={dropdownDirection}
        isActive={isOpen && !disabled}
        isControlledByIsActive
        handleClickOutside={() => handleClose(true)}
      >
        <div ref={dropdownRef}>
          <div className={`${DEFAULT_CLASS}__month-control`}>
            {`${MONTHS[currentDate.getMonth()]} ${currentDate.getFullYear()}`}
            <FlightButton
              iconLeft="baselineKeyboardArrowLeft"
              theme="minor"
              size="large"
              disabled={minDate && minDate.getTime() >= firstDayOfCurrentMonth.getTime()}
              onClick={() => toggleMonth(true)}
              ariaLabel="datepicker-go-to-previous-month"
            />
            <FlightButton
              iconRight="baselineKeyboardArrowRight"
              theme="minor"
              size="large"
              disabled={maxDate && maxDate.getTime() <= lastDayOfCurrentMonth.getTime()}
              onClick={() => toggleMonth(false)}
              ariaLabel="datepicker-go-to-next-month"
            />
          </div>
          <FlightTable
            className={`${DEFAULT_CLASS}__date-table`}
            tableHeaders={CALENDAR_TABLE_HEADERS}
            tableData={getMonthDaysOfDate(currentDate).map((wk, idx) => {
              const week = { ...wk };
              Object.keys(week).forEach((day) => {
                const dateNumber = week[day];
                const dateObj = new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  dateNumber,
                );

                const isToday = dateNumber === today.getDate()
                && currentDate.getMonth() === today.getMonth()
                && currentDate.getFullYear() === today.getFullYear();
                const isSelected = selected && dateNumber === selected.getDate()
                && currentDate.getMonth() === selected.getMonth()
                && currentDate.getFullYear() === selected.getFullYear();

                let dateButtonClass = `${DEFAULT_CLASS}__date-table__date`;
                dateButtonClass += isSelected ? ` ${dateButtonClass}--selected` : '';

                const dateButton = (
                  <FlightButton
                    className={dateButtonClass}
                    label={dateNumber}
                    theme="minor"
                    size="small"
                    disabled={((minDate && minDate.getTime() > dateObj.getTime())
                      || (maxDate && maxDate.getTime() < dateObj.getTime()))}
                    onClick={(() => handleCalenderPickDate(dateNumber))}
                  />
                );
                if (isToday) {
                  week[day] = (
                    <div className={`${DEFAULT_CLASS}__date-table__today`}>
                      {dateButton}
                      <div className={`${DEFAULT_CLASS}__date-table__today__indicator`} />
                    </div>
                  );
                } else {
                  week[day] = dateButton;
                }
              });

              week.key = idx;
              return week;
            })}
            hasPaginationBeforeTable={false}
          />
        </div>
      </FlightDropdown>
    </span>
  );
};

FlightDatePicker.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.instanceOf(Date),
  label: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  dropdownDirection: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  autoFocus: PropTypes.bool,
};

FlightDatePicker.defaultProps = {
  className: '',
  selected: null,
  label: 'Pick a date',
  onChange: () => undefined,
  onFocus: () => undefined,
  dropdownDirection: '',
  hasError: false,
  errorMessage: '',
  disabled: false,
  maxDate: null,
  minDate: null,
  autoFocus: false,
};

export default FlightDatePicker;
