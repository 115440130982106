import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { CHECKBOX_STATES, EVENT_KEYS } from '../constants';
import getIcon from '../utils/getIcon';
import './FlightCollapsible.scss';

const DEFAULT_CLASS = 'flight-collapsible';
const LABEL_CLASS = `${DEFAULT_CLASS}__label`;
const CONTENT_CLASS = `${DEFAULT_CLASS}__content`;

const FlightCollapsible = (props) => {
  const {
    className, disabled, onBlur, onClick, openMultiple, items,
  } = props;
  const stateObject = {};
  items.forEach((item) => {
    Object.assign(stateObject, {
      [item.key]: CHECKBOX_STATES.UNSELECTED,
    });
  });
  const [checkBoxState, setCheckBoxState] = useState(stateObject);

  const accordionRef = (element) => {
    if (element) {
      // Resize component width based on this.props.width
      /* eslint-disable no-param-reassign */
      element.style.maxHeight = props.maxHeight;
    }
  };

  const handleOnBlur = (event) => {
    if (disabled) return;
    if (onBlur) onBlur(event);
  };

  const handleOnClick = (event, triggeredItem) => {
    if (disabled || (triggeredItem && triggeredItem.disabled)) return;

    const nextStateObject = { ...checkBoxState };
    if (openMultiple) {
      if (checkBoxState[triggeredItem.key] === CHECKBOX_STATES.UNSELECTED) {
        nextStateObject[triggeredItem.key] = CHECKBOX_STATES.SELECTED;
      } else if (checkBoxState[triggeredItem.key] === CHECKBOX_STATES.SELECTED) {
        nextStateObject[triggeredItem.key] = CHECKBOX_STATES.UNSELECTED;
      }
    } else if (checkBoxState[triggeredItem.key] === CHECKBOX_STATES.UNSELECTED) {
      items.forEach((item) => {
        if (item.key === triggeredItem.key) {
          nextStateObject[item.key] = CHECKBOX_STATES.SELECTED;
        } else {
          nextStateObject[item.key] = CHECKBOX_STATES.UNSELECTED;
        }
      });
    } else if (checkBoxState[triggeredItem.key] === CHECKBOX_STATES.SELECTED) {
      nextStateObject[triggeredItem.key] = CHECKBOX_STATES.UNSELECTED;
    }

    setCheckBoxState(nextStateObject);
    if (onClick) onClick(event);
  };

  const handleOnEnter = (event, item) => {
    if (event.key === EVENT_KEYS.ENTER) handleOnClick(event, item);
  };

  let wrapperClass = `${DEFAULT_CLASS}-wrapper`;
  wrapperClass += className ? ` ${className}` : '';
  return (
    <div className={wrapperClass}>
      {
        items.map((item) => (
          <div
            className={`${DEFAULT_CLASS}${disabled || item.disabled
              ? ` ${DEFAULT_CLASS}--disabled` : ''}`}
            key={item.key}
          >
            <div
              className={`${LABEL_CLASS}${disabled || item.disabled
                ? ` ${LABEL_CLASS}--disabled` : ''}`}
              onBlur={handleOnBlur}
              onClick={(event) => handleOnClick(event, item)}
              onKeyDown={(event) => handleOnEnter(event, item)}
              role="checkbox"
              tabIndex={item.disabled || disabled ? '-1' : '0'}
              disabled={item.disabled || disabled}
              aria-checked={checkBoxState[item.key]}
            >
              {item.name}
              {getIcon(checkBoxState[item.key] === CHECKBOX_STATES.UNSELECTED
                ? 'baselineKeyboardArrowDown' : 'baselineKeyboardArrowUp',
              { className: `${LABEL_CLASS}__icon` })}
            </div>
            <div
              ref={accordionRef}
              className={`${CONTENT_CLASS}${(checkBoxState
            && checkBoxState[item.key] !== CHECKBOX_STATES.UNSELECTED
                ? ` ${CONTENT_CLASS}--selected` : '')}`}
            >
              {checkBoxState[item.key] === CHECKBOX_STATES.SELECTED
                ? item.content : null}
            </div>
          </div>
        ))
      }
    </div>
  );
};

FlightCollapsible.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  maxHeight: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  openMultiple: PropTypes.bool,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
      ]),
      disabled: PropTypes.bool,
      iconLeft: PropTypes.string,
      iconRight: PropTypes.string,
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string.isRequired,
    }),
  ),
};

FlightCollapsible.defaultProps = {
  className: '',
  disabled: false,
  maxHeight: '100px',
  onBlur: () => undefined,
  onClick: () => undefined,
  openMultiple: false,
  items: [],
};

export default FlightCollapsible;
