import React from 'react';
import PropTypes from 'prop-types';
import { CHECKBOX_STATES, EVENT_KEYS } from '../constants';
import getIcon from '../utils/getIcon';
import './FlightCheckbox.scss';

const DEFAULT_CLASS = 'flight-checkbox';
const ARIA_CHECKED_MAP = {
  [CHECKBOX_STATES.SELECTED]: 'true',
  [CHECKBOX_STATES.UNSELECTED]: 'false',
  [CHECKBOX_STATES.INDETERMINATE]: 'mixed',
};

const FlightCheckbox = (props) => {
  const {
    className, disabled, label, checkState,
  } = props;
  const checkIconProps = {
    className: `${DEFAULT_CLASS}__icon`,
  };
  const handleOnSelect = (event) => {
    const { onSelect } = props;
    if (disabled) return;
    if (onSelect) onSelect(event);
  };
  const handleOnEnter = (event) => {
    if (event.key === EVENT_KEYS.ENTER) handleOnSelect();
  };

  let wrapperClass = `${DEFAULT_CLASS}-wrapper`;
  wrapperClass += disabled ? ` ${wrapperClass}--disabled` : '';
  wrapperClass += className ? ` ${className}` : '';
  let mainClass = DEFAULT_CLASS;
  mainClass += (checkState && checkState !== CHECKBOX_STATES.UNSELECTED
    ? ` ${DEFAULT_CLASS}--${checkState.toLocaleLowerCase()}` : '');
  let icon = null;
  if (checkState === CHECKBOX_STATES.INDETERMINATE) {
    icon = getIcon('indeterminateCheckbox', checkIconProps);
  } else if (checkState === CHECKBOX_STATES.SELECTED) {
    icon = getIcon('checkboxCheck', checkIconProps);
  }

  return (
    <div
      className={wrapperClass}
      onClick={handleOnSelect}
      onKeyDown={handleOnEnter}
      role="checkbox"
      tabIndex={disabled ? '-1' : '0'}
      aria-checked={ARIA_CHECKED_MAP[checkState] || 'false'}
      aria-label={`checkbox-${label}`}
    >
      <div className={mainClass}>
        {icon}
      </div>
      <span className={`${DEFAULT_CLASS}-label`}>{label}</span>
    </div>
  );
};

FlightCheckbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  checkState: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

FlightCheckbox.defaultProps = {
  className: '',
  label: '',
  disabled: false,
  checkState: CHECKBOX_STATES.UNSELECTED,
};

export default FlightCheckbox;
