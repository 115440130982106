/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { EVENT_KEYS } from '../constants';
import FlightButton from '../flight-button/FlightButton';
import FlightTextInput from '../flight-text-input/FlightTextInput';
import FlightDropdown from '../flight-dropdown/FlightDropdown';
import './FlightSelectSearchable.scss';

const DEFAULT_CLASS = 'flight-select-searchable';
const DROPDOWN_CLASS = `${DEFAULT_CLASS}__dropdown`;
const DROPDOWN_OPTION_CLASS = `${DROPDOWN_CLASS}__option`;
const ERROR_CLASS = `${DEFAULT_CLASS}__error-message`;

const FlightSelectSearchable = (props) => {
  const {
    className, label, options, selected, dropdownMaxHeight,
    dropdownDirection, handleOptionClick, handleSearch,
    disabled, hasError, errorMessage, onFocus, autoFocus,
    onKeyDown,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  // if props.selected is has a value, and user tries to type
  //  in something else in the input, isReselecting helps the
  //  component to reset the input value to selected.name if
  //  the user does not select another option after changing
  //  the input value.
  const [isReselecting, setIsReselecting] = useState(false);
  const [hoveredOptIdx, setHoveredOptIdx] = useState(-1);
  const wrapperRef = (element) => {
    if (element) {
      // Resize component width based on props.width
      /* eslint-disable no-param-reassign */
      element.style.width = props.width;
    }
  };
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  useEffect(() => {
    if (isOpen) {
      if (selected && selected.key) {
        if (!isReselecting) {
          setIsReselecting(true);
        }
      } else if (options && options.length > 0) {
        setHoveredOptIdx(-1);
      }
    } else {
      let nextInputValue = '';
      if (selected && selected.key) {
        nextInputValue = selected.name;
      }
      setInputValue(nextInputValue);
    }
  }, [isOpen]);
  useEffect(() => {
    // this useEffect is for when the value of props.selected
    //  gets modified outside of the component.
    if (!(selected && selected.key)) {
      setInputValue('');
    }
  }, [selected]);
  const hoverOnOptionIdx = (OptionIdx) => {
    if (dropdownRef && dropdownRef.current) {
      // focusing on a dropdown option for the case when
      //  the option is outside of dropdown view.
      dropdownRef.current.children[OptionIdx !== -1 ? OptionIdx : 0].focus();
      // quickly switching back to focus on the input or
      //  else users will lose the ability to keep typing.
      inputRef.current.focus();
    }
  };
  useEffect(() => {
    // show hover state of options[index]
    hoverOnOptionIdx(hoveredOptIdx);
  }, [hoveredOptIdx]);
  const onOptionClick = (option) => {
    if (isOpen) {
      setIsOpen(false);
      setInputValue((option && option.name) || '');
      handleOptionClick(option);
      // reset search after and option is selected to show
      //  default options when open again.
      handleSearch('');
      inputRef.current.blur();
    }
  };
  const onWrapperKeyDown = (event) => {
    if ([
      EVENT_KEYS.ARROW_UP,
      EVENT_KEYS.ARROW_DOWN,
    ].includes(event.key)) {
      const newHoveredOptIdx = (event.key === EVENT_KEYS.ARROW_UP
        ? Math.max(hoveredOptIdx - 1, 0)
        : Math.min(hoveredOptIdx + 1, options.length - 1));
      setHoveredOptIdx(newHoveredOptIdx);
      event.preventDefault();
    } else if (event.key === EVENT_KEYS.ENTER) {
      let option = options[hoveredOptIdx];
      if (((option && option.name && typeof option.name !== 'string') || !option)
        && inputRef.current && inputRef.current.value) {
        option = { key: inputRef.current.value, name: inputRef.current.value };
      }
      onOptionClick(option);
    } else if (event.key === EVENT_KEYS.TAB) {
      // disabling tab key's effect within the component.
      setIsOpen(false);
      inputRef.current.blur();
    }
  };
  const handleTextOnChange = (event) => {
    // Reset option as hovered when text changes.
    setHoveredOptIdx(-1);
    setInputValue(event.target.value);
    handleSearch(event.target.value);
  };
  const handleInputOnFocus = (event) => {
    if (disabled) return;
    setIsOpen(true);
    if (event.target.value.length) {
      event.target.select();
    }
    if (onFocus) onFocus(event);
  };

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += disabled ? ` ${DEFAULT_CLASS}--disabled` : '';
  wrapperClass += className ? ` ${className}` : '';

  let dropdownClass = DROPDOWN_CLASS;
  dropdownClass += hasError ? ` ${DROPDOWN_CLASS}--error` : '';

  let errorMessageClass = ERROR_CLASS;
  errorMessageClass += disabled ? ` ${ERROR_CLASS}--disabled` : '';

  return (
    <div
      className={wrapperClass}
      ref={wrapperRef}
      onKeyDown={onWrapperKeyDown}
      role="button"
      tabIndex="-1"
    >
      <FlightDropdown
        className={dropdownClass}
        trigger={(
          <FlightTextInput
            className={`${DROPDOWN_CLASS}__input`}
            value={inputValue || (!isReselecting && selected
              && selected.name) || ''}
            label={label}
            onChange={handleTextOnChange}
            onFocus={handleInputOnFocus}
            trailingIcon={isOpen ? 'upCarrot' : 'downCarrot'}
            inputRef={inputRef}
            disabled={disabled}
            hasError={hasError}
            autoFocus={autoFocus}
            onKeyDown={onKeyDown}
          />
        )}
        direction={dropdownDirection}
        maxHeight={dropdownMaxHeight}
        isActive={isOpen && !disabled}
        isControlledByIsActive
        handleClickOutside={() => setIsOpen(false)}
      >
        <div ref={dropdownRef}>
          {options && options.length ? (
            options.map((option, idx) => {
              let optionButtonClassName = DROPDOWN_OPTION_CLASS;
              if (selected && selected.key === option.key) {
                optionButtonClassName += ` ${
                  DROPDOWN_OPTION_CLASS}--selected`;
              } else if (idx === hoveredOptIdx) {
                optionButtonClassName += ` ${
                  DROPDOWN_OPTION_CLASS}--hovered`;
              }
              return (
                <FlightButton
                  key={option.key}
                  helperkey={option.key}
                  className={optionButtonClassName}
                  label={option.name}
                  theme="minor"
                  onClick={() => onOptionClick(option)}
                  ariaLabel="option"
                />
              );
            })
          ) : (
            <div className={`${DROPDOWN_CLASS}__empty-message`}>
              No results found.
            </div>
          )}
        </div>
      </FlightDropdown>
      {hasError && errorMessage && (
        <span className={errorMessageClass}>{errorMessage}</span>
      )}
    </div>
  );
};

FlightSelectSearchable.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.node.isRequired,
  })),
  selected: PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.node.isRequired,
  }),
  onFocus: PropTypes.func,
  handleOptionClick: PropTypes.func,
  handleSearch: PropTypes.func,
  dropdownMaxHeight: PropTypes.string,
  dropdownDirection: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

FlightSelectSearchable.defaultProps = {
  label: 'Select an option',
  className: '',
  options: [],
  selected: null,
  onFocus: () => undefined,
  handleOptionClick: () => undefined,
  handleSearch: () => undefined,
  dropdownMaxHeight: '200px',
  dropdownDirection: '',
  hasError: false,
  errorMessage: '',
  width: '232px',
  disabled: false,
  autoFocus: false,
  onKeyDown: () => undefined,
};

export default FlightSelectSearchable;
