import React from 'react';
import PropTypes from 'prop-types';
import './FlightCard.scss';

const DEFAULT_CLASS = 'flight-card';

const FlightCard = (props) => {
  const {
    header, body, footer, actionables,
    image, className, onClick,
  } = props;

  let mainClass = DEFAULT_CLASS;
  mainClass += className ? ` ${className}` : '';
  return (
    <div
      className={mainClass}
      onClick={onClick}
      onKeyDown={() => undefined}
      role="button"
      tabIndex="-1"
    >
      {image ? (
        <div className={`${DEFAULT_CLASS}__image`}>
          {image}
        </div>
      ) : null}
      <div className={`${DEFAULT_CLASS}__header`}>
        {header}
      </div>
      {body ? (
        <div className={`${DEFAULT_CLASS}__body`}>
          {body}
        </div>
      ) : null}
      {footer ? (
        <div className={`${DEFAULT_CLASS}__footer`}>
          {footer}
        </div>
      ) : null}
      {actionables ? (
        <div className={`${DEFAULT_CLASS}__actionables`}>
          {actionables}
        </div>
      ) : null}
    </div>
  );
};

FlightCard.propTypes = {
  header: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  body: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  footer: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  actionables: PropTypes.element,
  image: PropTypes.element,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

FlightCard.defaultProps = {
  body: null,
  footer: null,
  actionables: null,
  image: null,
  className: '',
  onClick: () => undefined,
};

export default FlightCard;
