import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FlightButton from '../flight-button/FlightButton';
import './FlightOverflowMenu.scss';

const DEFAULT_CLASS = 'flight-overflow-menu';
const OUTSIDE_CLASS = `${DEFAULT_CLASS}__outside`;
const DROPDOWN_LIST_CLASS = `${DEFAULT_CLASS}__dropdown-list`;
const DROPDOWN_GROUP_CLASS = `${DROPDOWN_LIST_CLASS}__group`;
const NESTED_TRIGGER_CLASS = `${DROPDOWN_GROUP_CLASS}__nested-trigger`;
const removeElementsClassName = (dropdownLists, className) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const list of dropdownLists) {
    list.className = list.className.replace(
      new RegExp(` ${className}`, 'g'), '',
    );
  }
};
const mapOptions = (options, self) => {
  const mappedOptions = [];
  options.forEach((option) => {
    if (option.children && option.children.length) {
      let optionClass = NESTED_TRIGGER_CLASS;
      optionClass += (option.theme === 'error'
        ? ` ${NESTED_TRIGGER_CLASS}--error` : '');
      mappedOptions.push(
        <li key={option.key}>
          <FlightButton
            label={option.name}
            className={optionClass}
            iconLeft={option.iconLeft || ''}
            iconRight="baselineKeyboardArrowRight"
            theme="minor"
            size="small"
            disabled={option.disabled || false}
            onClick={self.handleNestedTriggerEnter}
            onMouseEnter={self.handleNestedTriggerEnter}
            ariaLabel={option.name || 'option'}
          />
          <FlightOverflowMenu
            optionGroups={option.children}
            isRoot={false}
            direction={self.props.direction}
            outsideRef={self.outsideReference}
          />
        </li>,
      );
    } else {
      const handleOptionClick = (event) => {
        option.onClick(event);
        self.outsideReference.current.click();
      };
      let optionClass = `${DROPDOWN_GROUP_CLASS}__option`;
      optionClass += (option.theme === 'error'
        ? ` ${DROPDOWN_GROUP_CLASS}__option--error` : '');
      mappedOptions.push(
        <FlightButton
          key={option.key}
          label={option.name}
          className={optionClass}
          onClick={handleOptionClick || (() => undefined)}
          iconLeft={option.iconLeft || ''}
          theme="minor"
          size="small"
          disabled={option.disabled || false}
          onMouseEnter={self.handleNestedTriggerEnter}
          ariaLabel={option.name || 'option'}
        />,
      );
    }
  });
  return mappedOptions;
};

const FlightOverflowMenu = (props) => {
  const {
    className, optionGroups, isRoot, isOpenRight,
    direction, disabled, outsideRef, label, iconLeft,
    iconRight,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [target, setTarget] = useState('');

  const outsideReference = outsideRef || React.createRef();

  const handleRootTriggerClick = (event) => {
    if (disabled) return;
    setIsOpen(true);
    setTarget(event.target);
  };

  const handleClickOutside = (event) => {
    if (disabled) return;
    const rootDropdownList = event.target.nextElementSibling;
    removeElementsClassName(rootDropdownList.getElementsByClassName(
      NESTED_TRIGGER_CLASS,
    ), `${NESTED_TRIGGER_CLASS}--selected`);
    removeElementsClassName(rootDropdownList.getElementsByClassName(
      DROPDOWN_LIST_CLASS,
    ), `${DROPDOWN_LIST_CLASS}--opened`);
    setIsOpen(false);
    event.stopPropagation();
  };

  const handleNestedTriggerEnter = (event) => {
    if (disabled) return;
    let parentDropdownList = event.target.parentElement;
    while (parentDropdownList.localName === 'svg'
    || !(parentDropdownList.className
      && parentDropdownList.className.split(' ').filter(
        (classNameInList) => classNameInList === DROPDOWN_GROUP_CLASS,
      ).length)) {
      parentDropdownList = parentDropdownList.parentElement;
    }
    const allDropdownLists = parentDropdownList.getElementsByClassName(
      DROPDOWN_LIST_CLASS,
    );
    removeElementsClassName(allDropdownLists,
      `${DROPDOWN_LIST_CLASS}--opened`);
    removeElementsClassName(parentDropdownList.getElementsByClassName(
      NESTED_TRIGGER_CLASS,
    ), `${NESTED_TRIGGER_CLASS}--selected`);
    if (event.target.className.includes
      && event.target.className.includes(NESTED_TRIGGER_CLASS)) {
      const currentChildrenDropdownList = (
        event.target.nextElementSibling.getElementsByClassName(
          DROPDOWN_LIST_CLASS,
        )[0]
      );
      /* eslint-disable no-param-reassign */
      event.target.classList.add(`${NESTED_TRIGGER_CLASS}--selected`);
      currentChildrenDropdownList.classList.add(
        `${DROPDOWN_LIST_CLASS}--opened`,
      );
      if (currentChildrenDropdownList.getBoundingClientRect().right
      > window.innerWidth
        && !currentChildrenDropdownList.className.includes(
          `${DROPDOWN_LIST_CLASS}--nested-right`,
        )) {
        currentChildrenDropdownList.classList.add(
          `${DROPDOWN_LIST_CLASS}--nested-left`,
        );
      } else if (currentChildrenDropdownList.getBoundingClientRect().left < 0
        && !currentChildrenDropdownList.className.includes(
          `${DROPDOWN_LIST_CLASS}--nested-left`,
        )) {
        currentChildrenDropdownList.classList.add(
          `${DROPDOWN_LIST_CLASS}--nested-right`,
        );
      }
      if (currentChildrenDropdownList.getBoundingClientRect().top < 0
      && currentChildrenDropdownList.className.includes(
        `${DROPDOWN_LIST_CLASS}--top`,
      )) {
        currentChildrenDropdownList.classList.remove(
          `${DROPDOWN_LIST_CLASS}--top`,
        );
      } else if (currentChildrenDropdownList.getBoundingClientRect().bottom
      > window.innerHeight) {
        currentChildrenDropdownList.classList.add(
          `${DROPDOWN_LIST_CLASS}--top`,
        );
      }
    }
  };

  const mappedGroups = [];
  optionGroups.forEach((options, idx) => {
    mappedGroups.push(
      // eslint-disable-next-line react/no-array-index-key
      <div className={DROPDOWN_GROUP_CLASS} key={idx}>
        {mapOptions(options, {
          handleNestedTriggerEnter,
          outsideReference,
          props: {
            direction,
          },

        })}
      </div>,
    );
  });

  useEffect(() => {
    let rootOverflowMenu = target.parentElement;
    while ((rootOverflowMenu && rootOverflowMenu.localName === 'svg')
    || (rootOverflowMenu && !(rootOverflowMenu.className
      && rootOverflowMenu.className.split(' ').filter(
        (RootClassName) => RootClassName === DEFAULT_CLASS,
      ).length))) {
      rootOverflowMenu = rootOverflowMenu.parentElement;
    }
    if (rootOverflowMenu
      && rootOverflowMenu.lastElementChild.getBoundingClientRect().bottom
      > window.innerHeight) {
      rootOverflowMenu.lastElementChild.classList.remove(
        `${DROPDOWN_LIST_CLASS}--bottom`,
      );
      rootOverflowMenu.lastElementChild.classList.add(
        `${DROPDOWN_LIST_CLASS}--top`,
      );
    } else if (rootOverflowMenu
      && rootOverflowMenu.lastElementChild.getBoundingClientRect().top < 0) {
      rootOverflowMenu.lastElementChild.classList.remove(
        `${DROPDOWN_LIST_CLASS}--top`,
      );
      rootOverflowMenu.lastElementChild.classList.add(
        `${DROPDOWN_LIST_CLASS}--bottom`,
      );
    }
  }, [isOpen, target]);

  let mainClass = DEFAULT_CLASS;
  mainClass += isRoot ? '' : ` ${DEFAULT_CLASS}--hover-appear`;
  mainClass += className ? ` ${className}` : '';
  let dropdownListClass = DROPDOWN_LIST_CLASS;
  dropdownListClass += isOpen ? ` ${DROPDOWN_LIST_CLASS}--opened` : '';
  dropdownListClass += ` ${DROPDOWN_LIST_CLASS}--${direction}`;
  dropdownListClass += (
    isRoot && isOpenRight) ? ` ${DROPDOWN_LIST_CLASS}--open-right` : '';
  dropdownListClass += isRoot ? '' : ` ${DROPDOWN_LIST_CLASS}--nested`;
  return (
    <div className={mainClass}>
      {isRoot ? (
        <React.Fragment>
          <FlightButton
            className={`${DEFAULT_CLASS}__trigger`}
            iconLeft={iconLeft}
            iconRight={iconRight}
            label={label}
            theme="minor"
            disabled={disabled}
            onClick={handleRootTriggerClick}
            ariaLabel="overflow-menu-dropdown-button"
          />
          <div
            className={`${OUTSIDE_CLASS}${isOpen ? ` ${OUTSIDE_CLASS}--opened` : ''}`}
            ref={outsideReference}
            onClick={handleClickOutside}
            onKeyDown={() => undefined}
            role="button"
            tabIndex="-1"
            aria-label="overflow-menu-outside"
          />
        </React.Fragment>
      ) : null}
      <ul className={dropdownListClass}>
        {mappedGroups}
      </ul>
    </div>
  );
};

FlightOverflowMenu.propTypes = {
  className: PropTypes.string,
  direction: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  isOpenRight: PropTypes.bool,
  optionGroups: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        iconLeft: PropTypes.string,
        key: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        name: PropTypes.string.isRequired,
        onClick: PropTypes.func,
        theme: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        children: PropTypes.any,
      }),
    ),
  ),
  // For nested overflow menus only
  isRoot: PropTypes.bool,
  outsideRef: PropTypes.shape({
    current: PropTypes.shape({
      click: PropTypes.func,
    }),
  }),
};

FlightOverflowMenu.defaultProps = {
  className: '',
  direction: 'bottom',
  disabled: false,
  label: '',
  iconLeft: '',
  iconRight: 'moreVert',
  isOpenRight: false,
  optionGroups: [],
  // For nested overflow menus only
  isRoot: true,
  outsideRef: null,
};

export default FlightOverflowMenu;
