/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CHECKBOX_STATES, EVENT_KEYS } from '../constants';
import truncateString from '../utils/truncateString';
import FlightButton from '../flight-button/FlightButton';
import FlightCheckbox from '../flight-checkbox/FlightCheckbox';
import FlightDropdown from '../flight-dropdown/FlightDropdown';
import FlightSearch from '../flight-search/FlightSearch';
import './FlightSelectMulti.scss';

const DEFAULT_CLASS = 'flight-multi-select';
const DROPDOWN_CLASS = `${DEFAULT_CLASS}__dropdown`;
const TRIGGER_CLASS = `${DROPDOWN_CLASS}__trigger__button`;
const DROPDOWN_OPTION_CLASS = `${DROPDOWN_CLASS}__option`;
const ERROR_CLASS = `${DEFAULT_CLASS}__error-message`;

const FlightSelectMulti = (props) => {
  const {
    className, label, options, dropdownMaxHeight, dropdownDirection,
    isLabelAlwaysShown, isSearchEnabled, handleOptionSelect, handleSearch,
    searchPlaceholder, disabled, hasError, errorMessage, onClick, optionMaxChar,
    autoFocus,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  // isFocused for handling styles changes when user
  //  is trying to do keyboard focus.
  const [isFocused, setIsFocused] = useState(false);
  // isSearchInputFocused decides if search text input needs to be
  //  focused on the next render, should be true for everytime
  //  the dropdown is being opened.
  const [isSearchInputFocused, setIsSearchInputFocused] = useState(false);
  const [focusedOptIdx, setFocusedOptIdx] = useState(0);

  // for focusing on the trigger after dropdown is closed and resizing
  // dropdown width based on trigger width
  const triggerRef = useRef(null);
  // for focusing on a dropdown option.
  const dropdownRef = useRef(null);
  const searchInputRef = useRef(null);
  const wrapperRef = (element) => {
    if (element && props.width) {
      // Resize component width based on props.width
      /* eslint-disable no-param-reassign */
      element.style.width = props.width;
    }
  };

  const selectedOptions = options.filter((option) => option.isSelected);
  const focusDropdownChild = (optionIdx) => {
    if (!optionIdx && isSearchEnabled
      && searchInputRef && searchInputRef.current) {
      // Allowing user to re-focus on search input when arrow up
      //  on first option
      searchInputRef.current.focus();
    } else if (optionIdx >= 0 && dropdownRef
      && dropdownRef.current && dropdownRef.current.children
      && dropdownRef.current.children[optionIdx]) {
      // Focus on the {optionIdx + 1}th option in the dropdown
      dropdownRef.current.children[optionIdx].focus();
    }
  };

  // For focusing on the option when search is not enabled
  useEffect(() => {
    if (isOpen && dropdownRef && dropdownRef.current
      && triggerRef && triggerRef.current) {
      // Focus on the (first option) or (the search input)
      //  when opening the dropdown for the first time
      focusDropdownChild(focusedOptIdx);
      dropdownRef.current.style.minWidth = `${
        triggerRef.current.getBoundingClientRect().width}px`;
    }
  }, [isOpen, focusedOptIdx, dropdownRef, selectedOptions.length]);

  const closeDropdown = (isFocusTrigger) => {
    setIsOpen(false);
    if (isFocusTrigger) triggerRef.current.focus();
  };
  const handleTriggerClick = (event) => {
    setIsOpen(true);
    setIsSearchInputFocused(true);
    if (onClick) onClick(event);
  };
  const handleOnKeyDown = (event) => {
    if (isOpen) {
      if (options && options.length > 0 && [
        EVENT_KEYS.ARROW_UP,
        EVENT_KEYS.ARROW_DOWN,
      ].includes(event.key)) {
        // focuses on the previous/next option when users
        //  input arrow up/down actions using keybard.
        //  using Math.min/max to handle boundaries. min
        //  depends on isSearchEnabled since FlightSearch
        //  will be the first child of the dropdown.
        const newFocusedOptIdx = (
          event.key === EVENT_KEYS.ARROW_UP
            ? Math.max(focusedOptIdx - 1, 0)
            : Math.min(focusedOptIdx + 1,
              options.length - (isSearchEnabled ? 0 : 1)));
        setFocusedOptIdx(newFocusedOptIdx);
        event.preventDefault();
      } else if (event.key === EVENT_KEYS.TAB) {
        // when users enter tab key they exit the component.
        closeDropdown(false);
      } else if (event.key === EVENT_KEYS.ESCAPE) {
        // when users enter escape key, close the dropdown but
        //  focus back to trigger button.
        closeDropdown(true);
      }
    }
  };
  const handleSearchInputOnLoad = () => {
    if (!disabled && isSearchInputFocused && searchInputRef
      && searchInputRef.current && searchInputRef.current.focus) {
      searchInputRef.current.focus();
      setIsSearchInputFocused(false);
    }
  };

  const handleTriggerKeydown = (event) => {
    if (!isOpen && [
      EVENT_KEYS.ARROW_UP,
      EVENT_KEYS.ARROW_DOWN,
    ].includes(event.key)) {
      setIsOpen(true);
      event.stopPropagation();
      event.preventDefault();
    }
  };

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += disabled ? ` ${DEFAULT_CLASS}--disabled` : '';
  wrapperClass += className ? ` ${className}` : '';

  let triggerClass = TRIGGER_CLASS;
  triggerClass += isOpen ? ` ${TRIGGER_CLASS}--open` : '';

  let dropdownClass = DROPDOWN_CLASS;
  dropdownClass += isSearchEnabled ? ` ${DROPDOWN_CLASS}--with-search` : '';
  dropdownClass += isFocused ? ` ${DROPDOWN_CLASS}--focused` : '';
  dropdownClass += hasError ? ` ${DROPDOWN_CLASS}--error` : '';

  let errorMessageClass = ERROR_CLASS;
  errorMessageClass += disabled ? ` ${ERROR_CLASS}--disabled` : '';

  const labelClass = `${DROPDOWN_CLASS}__trigger__button__label`;
  let triggerText = label;
  if (selectedOptions.length) {
    const labelText = `${label}: `;
    const moreOptionsText = ` + ${selectedOptions.length - 1} more`;

    if (isLabelAlwaysShown && selectedOptions.length > 1) {
      triggerText = (
        <React.Fragment>
          <span className={labelClass}>{labelText}</span>
          {truncateString(selectedOptions[0].name,
            optionMaxChar - (labelText.length + moreOptionsText.length)) + moreOptionsText}
        </React.Fragment>
      );
    } else if (isLabelAlwaysShown) {
      triggerText = (
        <React.Fragment>
          <span className={labelClass}>{labelText}</span>
          {truncateString(selectedOptions[0].name,
            optionMaxChar - labelText.length)}
        </React.Fragment>
      );
    } else if (selectedOptions.length > 1) {
      triggerText = truncateString(selectedOptions[0].name,
        optionMaxChar - moreOptionsText.length) + moreOptionsText;
    } else {
      triggerText = truncateString(selectedOptions[0].name, optionMaxChar);
    }
  }

  return (
    <div
      className={wrapperClass}
      ref={wrapperRef}
      onKeyDown={handleOnKeyDown}
      role="button"
      tabIndex="-1"
    >
      <FlightDropdown
        className={dropdownClass}
        trigger={(
          <FlightButton
            className={triggerClass}
            label={triggerText || ' '}
            theme="minor"
            onBlur={() => setIsFocused(false)}
            onClick={handleTriggerClick}
            onFocus={() => setIsFocused(true)}
            onKeyDown={handleTriggerKeydown}
            iconRight={isOpen ? 'upCarrot' : 'downCarrot'}
            disabled={disabled}
            buttonRef={triggerRef}
            isPropagateUpperActions
            ariaLabel="select-multi-dropdown-button"
          />
        )}
        direction={dropdownDirection}
        maxHeight={dropdownMaxHeight}
        isActive={isOpen}
        isControlledByIsActive
        handleClickOutside={() => closeDropdown(true)}
      >
        <div ref={dropdownRef}>
          {isSearchEnabled && (
            <FlightSearch
              placeholderText={searchPlaceholder}
              onSearch={handleSearch}
              width="100%"
              hasSearchIcon={false}
              searchInputRef={searchInputRef}
              onLoad={handleSearchInputOnLoad}
              autoFocus={autoFocus}
            />
          )}
          {options && options.length ? (
            options.map((option) => (
              <FlightCheckbox
                key={option.key}
                className={DROPDOWN_OPTION_CLASS}
                label={truncateString(option.name, optionMaxChar)}
                checkState={option.isSelected
                  ? CHECKBOX_STATES.SELECTED : CHECKBOX_STATES.UNSELECTED}
                onSelect={() => handleOptionSelect(option)}
              />
            ))
          ) : (
            <div className={`${DROPDOWN_CLASS}__empty-message`}>
              No options available
            </div>
          )}
        </div>
      </FlightDropdown>
      {hasError && errorMessage && (
        <span className={errorMessageClass}>{errorMessage}</span>
      )}
    </div>
  );
};

FlightSelectMulti.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
  })),
  onClick: PropTypes.func,
  isLabelAlwaysShown: PropTypes.bool,
  isSearchEnabled: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleOptionSelect: PropTypes.func,
  dropdownMaxHeight: PropTypes.string,
  dropdownDirection: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  optionMaxChar: PropTypes.number,
  searchPlaceholder: PropTypes.string,
  autoFocus: PropTypes.bool,
};

FlightSelectMulti.defaultProps = {
  label: 'Select options',
  className: '',
  options: [],
  onClick: () => undefined,
  isLabelAlwaysShown: false,
  isSearchEnabled: false,
  handleSearch: () => undefined,
  handleOptionSelect: () => undefined,
  dropdownMaxHeight: '232px',
  dropdownDirection: '',
  hasError: false,
  errorMessage: '',
  width: null,
  disabled: false,
  optionMaxChar: 40,
  searchPlaceholder: 'Search',
  autoFocus: false,
};

export default FlightSelectMulti;
