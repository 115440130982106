export const CHECKBOX_STATES = {
  UNSELECTED: 'UNSELECTED',
  INDETERMINATE: 'INDETERMINATE',
  SELECTED: 'SELECTED',
};

export const EVENT_KEYS = {
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  ARROW_UP: 'ArrowUp',
  ARROW_DOWN: 'ArrowDown',
  TAB: 'Tab',
};

export const CALENDAR_TABLE_HEADERS = [{
  name: 'S',
  key: 'Sunday',
  isVisible: true,
  isSortable: false,
  hideTooltip: true,
}, {
  name: 'M',
  key: 'Monday',
  isVisible: true,
  isSortable: false,
  hideTooltip: true,
}, {
  name: 'T',
  key: 'Tuesday',
  isVisible: true,
  isSortable: false,
  hideTooltip: true,
}, {
  name: 'W',
  key: 'Wednesday',
  isVisible: true,
  isSortable: false,
  hideTooltip: true,
}, {
  name: 'T',
  key: 'Thursday',
  isVisible: true,
  isSortable: false,
  hideTooltip: true,
}, {
  name: 'F',
  key: 'Friday',
  isVisible: true,
  isSortable: false,
  hideTooltip: true,
}, {
  name: 'S',
  key: 'Saturday',
  isVisible: true,
  isSortable: false,
  hideTooltip: true,
}];

export const MONTHS = [
  'January', 'February', 'March', 'April', 'May', 'June', 'July',
  'August', 'September', 'October', 'November', 'December',
];

export const WEEKDAYS = [
  'Sunday', 'Monday', 'Tuesday', 'Wednesday',
  'Thursday', 'Friday', 'Saturday',
];

export const MONTH_DAYS = [
  31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31,
];

export const MILLISECONDS_IN_MINUTE = 60000;
