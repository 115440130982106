/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import { EVENT_KEYS } from '../constants';
import FlightButton from '../flight-button/FlightButton';
import FlightDropdown from '../flight-dropdown/FlightDropdown';
import './FlightSelect.scss';

const DEFAULT_CLASS = 'flight-select';
const LABEL_CLASS = `${DEFAULT_CLASS}__label`;
const BORDER_CLASS = `${DEFAULT_CLASS}__border`;
const DROPDOWN_CLASS = `${DEFAULT_CLASS}__dropdown`;
const DROPDOWN_OPTION_CLASS = `${DROPDOWN_CLASS}__option`;
const ERROR_CLASS = `${DEFAULT_CLASS}__error-message`;

const FlightSelect = (props) => {
  const {
    className, label, options, selected, dropdownMaxHeight,
    dropdownDirection, hasLabelAnimation, handleOptionClick,
    disabled, hasError, errorMessage, onClick, hasIcon, searchRegex,
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  // isFocused for handling styles changes when user
  //  is trying to do keyboard focus.
  const [isFocused, setIsFocused] = useState(false);
  const [focusedOptIdx, setFocusedOptIdx] = useState(null);

  const wrapperRef = (element) => {
    if (element) {
      // Resize component width based on props.width
      /* eslint-disable no-param-reassign */
      element.style.width = props.width;
    }
  };

  const triggerRef = useRef(null);
  // for focusing on a dropdown option.
  const dropdownRef = useRef(null);

  const closeDropdown = (isFocusTrigger) => {
    setIsOpen(false);
    if (isFocusTrigger) triggerRef.current.focus();
  };

  const onOptionClick = (option, isCloseDropdown) => {
    handleOptionClick(option);
    if (isCloseDropdown) closeDropdown(true);
  };

  // focus on a matched option in the DOM dropdown by index.
  const focusOnOption = (index = -1) => {
    if (dropdownRef && dropdownRef.current && dropdownRef.current.children
      && index >= 0 && options[index] && options[index].name
      && index < dropdownRef.current.children.length) {
      setFocusedOptIdx(index);
      dropdownRef.current.children[index].focus();
    }
  };

  // to focus on selected option or first option by default
  useEffect(() => {
    if (isOpen && options && options.length > 0) {
      const selectedIdx = selected && selected.key
        ? options.findIndex((opt) => opt.key === selected.key) : 0;
      // auto focus on selected option when dropdown opens.
      //  if props.selected is not defined or that the selected
      //  option is not found from options list, try to select
      //  the first option in the options list.
      focusOnOption(Math.max(selectedIdx, 0));
    }
  }, [isOpen]);

  const handleOnClick = (event) => {
    if (disabled) return;
    setIsOpen(true);
    if (onClick) onClick(event);
  };

  const handleFindOptionOnKeyDown = (event) => {
    const reg = new RegExp('^[a-zA-Z0-9]$');
    if (isOpen) {
      if (reg.test(event.key)) {
        // finds the idx of first option in the list that matches
        //  the letter or number the user just typed.
        let matchedIdx = options.findIndex(
          (opt) => {
            const optionWrapper = document.createElement('span');
            optionWrapper.innerHTML = ReactDOMServer.renderToStaticMarkup(opt.name);
            return optionWrapper.textContent.toLocaleLowerCase()[0]
              === event.key.toLocaleLowerCase();
          },
        );
        if (searchRegex) {
          const regex = new RegExp(searchRegex + event.key, 'i');
          matchedIdx = options.findIndex(
            (opt) => {
              const optionWrapper = document.createElement('span');
              optionWrapper.innerHTML = ReactDOMServer.renderToStaticMarkup(opt.name);
              return regex.test(optionWrapper.textContent);
            },
          );
        }
        if (matchedIdx > -1) focusOnOption(matchedIdx);
      } else if (options && options.length > 0 && [
        EVENT_KEYS.ARROW_UP,
        EVENT_KEYS.ARROW_DOWN,
      ].includes(event.key)) {
        // focuses on the previous/next option when users
        //  input arrow up/down actions using keyboard.
        const newFocusedOptIdx = (
          event.key === EVENT_KEYS.ARROW_UP
            ? Math.max(focusedOptIdx - 1, 0)
            : Math.min(focusedOptIdx + 1, options.length - 1));
        focusOnOption(newFocusedOptIdx);
        event.preventDefault();
      } else if (event.key === EVENT_KEYS.TAB) {
        // when users enter tab key they exit the component.
        closeDropdown(false);
      } else if (event.key === EVENT_KEYS.ESCAPE) {
        // when users enter escape key, close the dropdown but
        //  focus back to trigger button.
        closeDropdown(true);
      }
    }
  };

  const handleTriggerKeydown = (event) => {
    if (!isOpen && [
      EVENT_KEYS.ARROW_UP,
      EVENT_KEYS.ARROW_DOWN,
    ].includes(event.key)) {
      setIsOpen(true);
      event.stopPropagation();
      event.preventDefault();
    }
  };

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += (label && label.length && hasLabelAnimation
    ? ` ${DEFAULT_CLASS}--label-animation` : '');
  wrapperClass += disabled ? ` ${DEFAULT_CLASS}--disabled` : '';
  wrapperClass += className ? ` ${className}` : '';

  let dropdownClass = DROPDOWN_CLASS;
  dropdownClass += isFocused ? ` ${DROPDOWN_CLASS}--focused` : '';
  dropdownClass += hasError ? ` ${DROPDOWN_CLASS}--error` : '';

  let borderClass = BORDER_CLASS;
  borderClass += isOpen ? ` ${BORDER_CLASS}--open` : '';
  borderClass += hasError ? ` ${BORDER_CLASS}--error` : '';

  let labelClass = LABEL_CLASS;
  labelClass += isOpen ? ` ${LABEL_CLASS}--open` : '';
  labelClass += ((hasLabelAnimation && (isOpen
    || (selected && selected.key))) ? ` ${LABEL_CLASS}--up` : '');
  labelClass += hasError ? ` ${LABEL_CLASS}--error` : '';

  let errorMessageClass = ERROR_CLASS;
  errorMessageClass += disabled ? ` ${ERROR_CLASS}--disabled` : '';

  let icon = '';
  if (hasIcon && isOpen) {
    icon = 'upCarrot';
  } else if (hasIcon) {
    icon = 'downCarrot';
  }

  return (
    <div
      className={wrapperClass}
      ref={wrapperRef}
      onKeyDown={handleFindOptionOnKeyDown}
      role="button"
      tabIndex="-1"
    >
      <FlightDropdown
        className={dropdownClass}
        trigger={(
          <FlightButton
            className={`${DROPDOWN_CLASS}__trigger__button`}
            label={selected && selected.name ? selected.name : ' '}
            theme="minor"
            onBlur={() => setIsFocused(false)}
            onClick={handleOnClick}
            onFocus={() => setIsFocused(true)}
            onKeyDown={handleTriggerKeydown}
            iconRight={icon}
            disabled={disabled}
            buttonRef={triggerRef}
            isPropagateUpperActions
            ariaLabel="select-dropdown-button"
          />
        )}
        direction={dropdownDirection}
        maxHeight={dropdownMaxHeight}
        isActive={isOpen}
        isControlledByIsActive
        handleClickOutside={() => closeDropdown(true)}
      >
        <div ref={dropdownRef}>
          {options && options.length ? (
            options.map((option) => (
              <FlightButton
                key={option.key}
                helperkey={option.key.toString()}
                className={`${DROPDOWN_OPTION_CLASS}${
                  selected && selected.key === option.key
                    ? ` ${DROPDOWN_OPTION_CLASS}--selected` : ''
                }`}
                label={option.name}
                theme="minor"
                onClick={() => onOptionClick(option, true)}
                ariaLabel="option"
              />
            ))
          ) : (
            <div className={`${DROPDOWN_CLASS}__empty-message`}>
              No options available
            </div>
          )}
        </div>
      </FlightDropdown>
      {label && label.length && (!selected || hasLabelAnimation) && (
        <div className={labelClass}>{label}</div>
      )}
      <div className={borderClass} />
      {hasError && errorMessage && (
        <span className={errorMessageClass}>{errorMessage}</span>
      )}
    </div>
  );
};

FlightSelect.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.node.isRequired,
  })),
  selected: PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.node.isRequired,
  }),
  onClick: PropTypes.func,
  hasLabelAnimation: PropTypes.bool,
  handleOptionClick: PropTypes.func,
  dropdownMaxHeight: PropTypes.string,
  dropdownDirection: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  hasIcon: PropTypes.bool,
  searchRegex: PropTypes.string,
};

FlightSelect.defaultProps = {
  label: 'Select an option',
  className: '',
  options: [],
  selected: null,
  onClick: () => undefined,
  hasLabelAnimation: false,
  handleOptionClick: () => undefined,
  dropdownMaxHeight: '200px',
  dropdownDirection: '',
  hasError: false,
  errorMessage: '',
  width: '232px',
  disabled: false,
  hasIcon: true,
  searchRegex: '',
};

export default FlightSelect;
