import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FlightButton from '../flight-button/FlightButton';
import getIcon from '../utils/getIcon';
import './FlightSnackbar.scss';

const DEFAULT_CLASS = 'flight-snackbar';
const AUTO_DISMISS_TIMER = 5000;
const SNACKBAR_TYPES_MAP = {
  default: 'default',
  success: 'success',
  error: 'error',
  info: 'info',
  warning: 'warning',
};
const TYPES_ICON_MAP = {
  default: null,
  success: getIcon('checkCircle'),
  error: getIcon('blocked'),
  info: getIcon('infoFilled'),
  warning: getIcon('error'),
};

const FlightSnackbar = (props) => {
  const {
    isVisible, isFloating, animation, icon,
    title, content, type, isAutoDismiss, dismissAfter,
    action, actionName, handleClose, className,
  } = props;
  const iconElement = getIcon(icon) || TYPES_ICON_MAP[type];

  const dismissTimer = useRef();

  const handleDismiss = () => {
    clearTimeout(dismissTimer.current);
    handleClose();
  };
  if (isVisible && isAutoDismiss) {
    dismissTimer.current = setTimeout(handleDismiss, dismissAfter);
  }
  useEffect(() => () => {
    clearTimeout(dismissTimer.current);
  }, []);

  let mainClass = DEFAULT_CLASS;
  mainClass += isFloating ? ` ${DEFAULT_CLASS}--floating` : '';
  mainClass += animation ? ` ${DEFAULT_CLASS}--animation` : '';
  mainClass += (SNACKBAR_TYPES_MAP[type] ? ` ${DEFAULT_CLASS}--${SNACKBAR_TYPES_MAP[type]}`
    : ` ${DEFAULT_CLASS}--${SNACKBAR_TYPES_MAP.default}`);
  mainClass += title ? ` ${DEFAULT_CLASS}--with-title` : '';
  mainClass += iconElement ? '' : ` ${DEFAULT_CLASS}--without-icon`;
  mainClass += className ? ` ${className}` : '';
  return (
    <React.Fragment>
      {isVisible && (
        <div className={mainClass}>
          {iconElement
            && (
            <div className={`${DEFAULT_CLASS}__icon`}>
              {iconElement}
            </div>
            )}
          {title && <div className={`${DEFAULT_CLASS}__title`}>{title}</div>}
          <div className={`${DEFAULT_CLASS}__content`}>{content}</div>
          <div className={`${DEFAULT_CLASS}__actions`}>
            {action
              && (
              <FlightButton
                className={`${DEFAULT_CLASS}__actions__button`}
                label={actionName}
                theme="link"
                onClick={action}
                hasUnderline={false}
                ariaLabel={actionName || 'snackbar-action'}
              />
              )}
            {!isAutoDismiss
              && (
              <FlightButton
                className={`${DEFAULT_CLASS}__actions__button`}
                label="Dismiss"
                theme="link"
                onClick={handleDismiss}
                hasUnderline={false}
              />
              )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

FlightSnackbar.propTypes = {
  className: PropTypes.string,
  isVisible: PropTypes.bool,
  isFloating: PropTypes.bool,
  animation: PropTypes.bool,
  icon: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.node,
  type: PropTypes.string,
  isAutoDismiss: PropTypes.bool,
  dismissAfter: PropTypes.number,
  action: PropTypes.func,
  actionName: PropTypes.string,
  handleClose: PropTypes.func,
};

FlightSnackbar.defaultProps = {
  className: '',
  isVisible: false,
  isFloating: true,
  animation: false,
  icon: '',
  title: '',
  content: '',
  type: 'default',
  isAutoDismiss: false,
  dismissAfter: AUTO_DISMISS_TIMER,
  action: () => undefined,
  actionName: 'Action',
  handleClose: () => undefined,
};

export default FlightSnackbar;
