import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import getIcon from '../utils/getIcon';
import './FlightTextInput.scss';

const INPUT_TYPES = {
  text: 'text',
  password: 'password',
};
const DEFAULT_MAX_LENGTH = 250;
const DEFAULT_CLASS = 'flight-text-input';
const INPUT_CLASS = `${DEFAULT_CLASS}__input`;
const ERROR_CLASS = `${DEFAULT_CLASS}__error-message`;

const FlightTextInput = (props) => {
  const {
    className, disabled, errorMessage, hasClearIcon,
    hasError, iconInput, inputRef, label, name,
    placeholderText, trailingIcon, type, value, width,
    onLoad, onChange, onBlur, onFocus, onKeyDown, onKeyPress,
    maxLength, isLabelAlwaysOn, autoFocus,
  } = props;

  const [isShowPassword, setIsShowPassword] = useState(false);

  const wrapperRef = (element) => {
    if (element) {
      // Resize component width based on this.props.width
      /* eslint-disable no-param-reassign */
      element.style.width = width;
    }
  };
  let ref = inputRef;
  if (autoFocus && !inputRef) ref = React.createRef();

  useEffect(() => {
    if (onLoad) onLoad();
    if (autoFocus && ref && ref.current) ref.current.focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleValueChange = (event) => {
    if (disabled) return;
    event.target.value = event.target.value.slice(0, maxLength);
    if (onChange) onChange(event);
  };

  const handleOnBlur = (event) => {
    if (disabled) return;
    if (onBlur) onBlur(event);
  };

  const handleOnFocus = (event) => {
    if (disabled) return;
    if (onFocus) onFocus(event);
  };

  const handleKeyDown = (event) => {
    if (disabled) return;
    if (onKeyDown) onKeyDown(event);
  };

  const handleKeyPress = (event) => {
    if (disabled) return;
    if (onKeyPress) onKeyPress(event);
  };

  const handleTrailingIconClear = () => {
    handleValueChange({ target: { value: '' } });
  };

  const handleToggleShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  let labelWrapperClass = DEFAULT_CLASS;
  labelWrapperClass += (iconInput
    ? ` ${DEFAULT_CLASS}--with-icon-input` : '');
  labelWrapperClass += hasError ? ` ${DEFAULT_CLASS}--error` : '';
  labelWrapperClass += disabled ? ` ${DEFAULT_CLASS}--disabled` : '';
  labelWrapperClass += className ? ` ${className}` : '';

  let inputClass = INPUT_CLASS;
  inputClass += (value && hasClearIcon
    ? ` ${INPUT_CLASS}--with-clear-icon` : '');
  inputClass += !label ? ` ${INPUT_CLASS}--without-label` : '';
  inputClass += !isLabelAlwaysOn ? ` ${INPUT_CLASS}--focus-hide-label` : '';
  inputClass += value ? ` ${INPUT_CLASS}--not-empty` : '';

  let errorMessageClass = ERROR_CLASS;
  errorMessageClass += disabled ? ` ${ERROR_CLASS}--disabled` : '';

  let iconInputTrailingClass = `${DEFAULT_CLASS}__icon-trailing`;
  iconInputTrailingClass += (type !== INPUT_TYPES.password && hasError
    && (!value || !hasClearIcon)
    ? ` ${DEFAULT_CLASS}__icon-trailing--error` : '');

  let iconInputTrailing = null;
  if (type === INPUT_TYPES.password) {
    const passwordIconProps = {
      className: iconInputTrailingClass,
      onClick: handleToggleShowPassword,
    };
    iconInputTrailing = (isShowPassword
      ? getIcon('eyeHide', passwordIconProps)
      : getIcon('eyeOpen', passwordIconProps));
  } else if (trailingIcon) {
    iconInputTrailing = getIcon(trailingIcon, {
      className: iconInputTrailingClass,
    });
  } else if (hasClearIcon && value.length > 0 && !disabled) {
    iconInputTrailing = getIcon('clear', {
      className: iconInputTrailingClass,
      onClick: handleTrailingIconClear,
    });
  } else if (hasError) {
    iconInputTrailing = getIcon('error', {
      className: iconInputTrailingClass,
    });
  }

  return (
    <div className="flight-text-input-wrapper" ref={wrapperRef}>
      <label className={labelWrapperClass} htmlFor="text-input">
        {
          iconInput && (
            <div className={`${DEFAULT_CLASS}__icon-input`}>
              {getIcon(iconInput, {
                className: `${DEFAULT_CLASS}__icon-input__svg`,
              })}
            </div>
          )
        }
        {iconInputTrailing}
        <input
          className={inputClass}
          disabled={disabled}
          name={name}
          onChange={handleValueChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          onKeyDown={handleKeyDown}
          onKeyPress={handleKeyPress}
          placeholder={placeholderText}
          autoComplete={(type === INPUT_TYPES.password
            ? 'current-password' : '')}
          ref={ref}
          type={(type === INPUT_TYPES.password && !isShowPassword
            ? INPUT_TYPES.password : INPUT_TYPES.text)}
          value={value}
        />
        {label && (!value || isLabelAlwaysOn) && (
          <span className={`${DEFAULT_CLASS}__label`}>
            {label}
          </span>
        )}
        <span className={`${DEFAULT_CLASS}__border`} />
      </label>
      {hasError && errorMessage && (
        <span className={errorMessageClass}>{errorMessage}</span>
      )}
    </div>
  );
};

FlightTextInput.propTypes = {
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  hasClearIcon: PropTypes.bool,
  hasError: PropTypes.bool,
  iconInput: PropTypes.string,
  inputRef: PropTypes.shape({
    current: PropTypes.shape({
      focus: PropTypes.func,
    }),
  }),
  isLabelAlwaysOn: PropTypes.bool,
  label: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyPress: PropTypes.func,
  onLoad: PropTypes.func,
  name: PropTypes.string,
  maxLength: PropTypes.number,
  placeholderText: PropTypes.string,
  trailingIcon: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.string,
};

FlightTextInput.defaultProps = {
  autoFocus: false,
  className: '',
  disabled: false,
  errorMessage: null,
  hasClearIcon: false,
  hasError: false,
  iconInput: '',
  inputRef: null,
  isLabelAlwaysOn: true,
  label: '',
  name: null,
  maxLength: DEFAULT_MAX_LENGTH,
  onBlur: () => undefined,
  onFocus: () => undefined,
  onChange: () => undefined,
  onKeyDown: () => undefined,
  onKeyPress: () => undefined,
  onLoad: () => undefined,
  placeholderText: '',
  trailingIcon: '',
  type: 'text',
  value: '',
  width: '232px',
};

export default FlightTextInput;
