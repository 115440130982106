import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import FlightTextInput from '../flight-text-input/FlightTextInput';
import useDebounce from '../hooks/useDebounce';

const DEFAULT_CLASS = 'flight-search';

const FlightSearch = (props) => {
  const {
    placeholderText, width, disabled, className, onSearch,
    autoSearchDelay, searchInputRef, hasSearchIcon, onLoad,
    autoFocus,
  } = props;

  const [textInputValue, setTextInputValue] = useState('');
  const debouncedValue = useDebounce(textInputValue, autoSearchDelay);
  // isDirty = true after component loads
  const isDirty = useRef(false);

  useEffect(() => {
    if (!isDirty.current) {
      isDirty.current = true;
      return;
    }

    onSearch(debouncedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += className ? ` ${className}` : '';

  return (
    <div className={wrapperClass}>
      <FlightTextInput
        name="search"
        iconInput={hasSearchIcon ? 'search' : ''}
        hasClearIcon
        placeholderText={placeholderText}
        width={width}
        value={textInputValue}
        onChange={(event) => setTextInputValue(event.target.value)}
        onLoad={onLoad}
        disabled={disabled}
        inputRef={searchInputRef}
        autoFocus={autoFocus}
      />
    </div>
  );
};

FlightSearch.propTypes = {
  placeholderText: PropTypes.string,
  width: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onLoad: PropTypes.func,
  autoFocus: PropTypes.bool,
  autoSearchDelay: PropTypes.number,
  searchInputRef: PropTypes.shape({
    current: PropTypes.shape({
      focus: PropTypes.func,
    }),
  }),
  hasSearchIcon: PropTypes.bool,
};

FlightSearch.defaultProps = {
  placeholderText: '',
  width: '232px',
  disabled: false,
  className: '',
  onLoad: () => undefined,
  autoFocus: false,
  autoSearchDelay: 500,
  searchInputRef: null,
  hasSearchIcon: true,
};

export default FlightSearch;
