/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { MONTHS } from '../constants';
import getDateString from '../utils/getDateString';
import FlightButton from '../flight-button/FlightButton';
import FlightCalendar from '../flight-calendar/FlightCalendar';
import FlightDropdown from '../flight-dropdown/FlightDropdown';
import './FlightDateRangePicker.scss';

const DEFAULT_CLASS = 'flight-date-range-picker';
const DROPDOWN_CLASS = `${DEFAULT_CLASS}__dropdown`;
const TRIGGER_CLASS = `${DROPDOWN_CLASS}__trigger__button`;
const CUSTOM_DROPDOWN_CLASS = `${DROPDOWN_CLASS}__custom`;
const DROPDOWN_OPTIONS = {
  SEVEN_DAYS: 'Last 7 days',
  THIRTY_DAYS: 'Last 30 days',
  SIXTY_DAYS: 'Last 60 days',
  ALL: 'All time',
};
const DROPDOWN_OPTIONS_VALUE = {
  SEVEN_DAYS: 7,
  THIRTY_DAYS: 30,
  SIXTY_DAYS: 60,
};

const isValidDate = (date) => {
  if (!date || Number.isNaN(new Date(date).getTime())) {
    return false;
  }

  return true;
};
const getLabelByDateRange = (startDate, endDate) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  let label = DROPDOWN_OPTIONS.ALL;

  if (isValidDate(startDate) && isValidDate(endDate)
  && endDate.getTime() === today.getTime()) {
    const sevenDaysAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - DROPDOWN_OPTIONS_VALUE.SEVEN_DAYS,
    );
    const thirtyDaysAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - DROPDOWN_OPTIONS_VALUE.THIRTY_DAYS,
    );
    const sixtyDaysAgo = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - DROPDOWN_OPTIONS_VALUE.SIXTY_DAYS,
    );

    switch (startDate.getTime()) {
      case sevenDaysAgo.getTime():
        return DROPDOWN_OPTIONS.SEVEN_DAYS;
      case thirtyDaysAgo.getTime():
        return DROPDOWN_OPTIONS.THIRTY_DAYS;
      case sixtyDaysAgo.getTime():
        return DROPDOWN_OPTIONS.SIXTY_DAYS;
      default:
    }
  }

  if ((startDate && !isValidDate(startDate))
  || (endDate && !isValidDate(endDate))) {
    return 'Invalid date';
  } if (isValidDate(startDate)) {
    label = `${getDateString(startDate, false)} -`;
    if (isValidDate(endDate)) {
      label += ` ${getDateString(endDate, false)}`;
    }
  }

  return label;
};
const FlightDateRangePicker = (props) => {
  const {
    className, value, dropdownDirection, onChange,
    onClick, disabled, hasError, errorMessage,
    maxDate, minDate, isIncludeCustom,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [label, setLabel] = useState(DROPDOWN_OPTIONS.ALL);
  const [isCustom, setIsCustom] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());

  if (maxDate) maxDate.setHours(0, 0, 0, 0);
  if (minDate) minDate.setHours(0, 0, 0, 0);

  const firstDayOfCurrentMonth = new Date(
    currentDate.getFullYear(), currentDate.getMonth(), 1,
  );
  const firstDayOfNextMonth = new Date(
    currentDate.getFullYear(), currentDate.getMonth() + 1, 1,
  );
  const lastDayOfNextMonth = new Date(
    currentDate.getFullYear(), currentDate.getMonth() + 2, 0,
  );

  const triggerRef = useRef();

  useEffect(() => {
    if (value) {
      setLabel(getLabelByDateRange(value[0], value[1]));
      if (isValidDate(value[0])) {
        setStartDate(value[0]);
        if (isValidDate(value[1])) setEndDate(value[1]);
        else setEndDate(null);
      }
    }
  }, [value]);

  useEffect(() => {
    if (isOpen && isCustom) {
      setCurrentDate(value && value[0] && isValidDate(value[0])
        ? value[0] : new Date());
    }
  }, [value, isOpen, isCustom]);

  const handleClose = () => {
    if (triggerRef && triggerRef.current) {
      triggerRef.current.focus();
    }
    setIsOpen(false);
  };

  const handleSelectPresetRange = (option) => {
    const end = new Date();
    end.setHours(0, 0, 0, 0);
    const start = new Date(end);

    if (DROPDOWN_OPTIONS_VALUE[option]) {
      start.setDate(start.getDate() - DROPDOWN_OPTIONS_VALUE[option]);
      onChange([start, end]);
    } else {
      onChange([null, null]);
    }

    handleClose();
  };

  const handleCalendarPickDate = (selectDate) => {
    if (disabled) return;

    if (!startDate || startDate.getTime() > selectDate.getTime()
    || (startDate && endDate)) {
      onChange([selectDate, null]);
    } else {
      onChange([startDate, selectDate]);
      handleClose();
    }
  };

  const toggleMonth = (isPrevious) => {
    const nextCurrentDate = new Date(currentDate);
    nextCurrentDate.setMonth(currentDate.getMonth() + (isPrevious ? -1 : 1));
    setCurrentDate(nextCurrentDate);
  };

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += className ? ` ${className}` : '';

  let dropdownClass = DROPDOWN_CLASS;
  dropdownClass += disabled ? ` ${DROPDOWN_CLASS}--disabled` : '';

  let triggerClass = TRIGGER_CLASS;
  triggerClass += isOpen ? ` ${TRIGGER_CLASS}--open` : '';
  triggerClass += hasError ? ` ${TRIGGER_CLASS}--error` : '';

  let triggerIcon = 'downCarrot';
  if (hasError) triggerIcon = 'error';
  else if (isOpen) triggerIcon = 'upCarrot';

  return (
    <span className={wrapperClass}>
      <FlightDropdown
        className={dropdownClass}
        trigger={(
          <React.Fragment>
            <FlightButton
              className={triggerClass}
              label={label}
              theme="minor"
              iconRight={triggerIcon}
              onClick={(e) => {
                onClick(e);
                setIsOpen(!isOpen);
              }}
              disabled={disabled}
              buttonRef={triggerRef}
            />
            <span className={`${DROPDOWN_CLASS}__border`} />
          </React.Fragment>
        )}
        direction={dropdownDirection}
        isActive={isOpen && !disabled}
        isControlledByIsActive
        handleClickOutside={handleClose}
      >
        <React.Fragment>
          {Object.keys(DROPDOWN_OPTIONS).map((option) => (
            <FlightButton
              key={option}
              className={`${DROPDOWN_CLASS}__option`}
              label={DROPDOWN_OPTIONS[option]}
              theme="minor"
              size="small"
              onClick={() => handleSelectPresetRange(option)}
              onFocus={() => setIsCustom(false)}
            />
          ))}
          {isIncludeCustom && (
            <FlightDropdown
              className={CUSTOM_DROPDOWN_CLASS}
              trigger={(
                <FlightButton
                  className={`${DROPDOWN_CLASS}__option`}
                  label="Custom"
                  theme="minor"
                  size="small"
                  onClick={() => !isCustom && setIsCustom(true)}
                  onFocus={() => setIsCustom(true)}
                />
              )}
              direction="right"
              isActive={isCustom}
              isControlledByIsActive
              handleClickOutside={() => setIsCustom(false)}
            >
              <React.Fragment>
                <div className={`${CUSTOM_DROPDOWN_CLASS}__calendar`}>
                  <div className={`${CUSTOM_DROPDOWN_CLASS}__calendar__control`}>
                    {`${MONTHS[currentDate.getMonth()]}
                    ${currentDate.getFullYear()}`}
                    <FlightButton
                      iconLeft="baselineKeyboardArrowLeft"
                      theme="minor"
                      size="large"
                      disabled={minDate
                        && minDate.getTime() >= firstDayOfCurrentMonth.getTime()}
                      onClick={() => toggleMonth(true)}
                      ariaLabel="datepicker-go-to-previous-month"
                    />
                  </div>
                  <FlightCalendar
                    currentDate={currentDate}
                    onSelect={handleCalendarPickDate}
                    startDate={startDate}
                    endDate={endDate}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                </div>
                <div className={`${CUSTOM_DROPDOWN_CLASS}__calendar`}>
                  <div className={`${CUSTOM_DROPDOWN_CLASS}__calendar__control`}>
                    {`${MONTHS[firstDayOfNextMonth.getMonth()]}
                    ${firstDayOfNextMonth.getFullYear()}`}
                    <FlightButton
                      iconRight="baselineKeyboardArrowRight"
                      theme="minor"
                      size="large"
                      disabled={maxDate
                        && maxDate.getTime() <= lastDayOfNextMonth.getTime()}
                      onClick={() => toggleMonth(false)}
                      ariaLabel="datepicker-go-to-next-month"
                    />
                  </div>
                  <FlightCalendar
                    currentDate={firstDayOfNextMonth}
                    onSelect={handleCalendarPickDate}
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={maxDate}
                    minDate={minDate}
                  />
                </div>
              </React.Fragment>
            </FlightDropdown>
          )}
        </React.Fragment>
      </FlightDropdown>
      {hasError && errorMessage && (
        <span className={`${DEFAULT_CLASS}__error-message`}>
          {errorMessage}
        </span>
      )}
    </span>
  );
};

FlightDateRangePicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  dropdownDirection: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  isIncludeCustom: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
};

FlightDateRangePicker.defaultProps = {
  className: '',
  value: [null, null],
  onClick: () => undefined,
  dropdownDirection: '',
  hasError: false,
  errorMessage: '',
  disabled: false,
  isIncludeCustom: false,
  maxDate: null,
  minDate: null,
};

export default FlightDateRangePicker;
