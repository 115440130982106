import React from 'react';
import PropTypes from 'prop-types';
import FlightSelect from '../flight-select/FlightSelect';
import './FlightTimeZone.scss';
import tz from '../constants/tz';

const DEFAULT_CLASS = 'flight-time-zone';

const FlightTimeZone = (props) => {
  const {
    className, disabled, selectedTimeZone, handleTimeZoneChange,
    width, errorMessage, hasError, timeZoneList, label, timeZoneRegex,
  } = props;

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += className ? ` ${className}` : '';

  let matchedTimeZone;

  if (selectedTimeZone !== null) {
    matchedTimeZone = timeZoneList
      .find((timeZone) => timeZone.key.toString() === selectedTimeZone.key.toString()
      && timeZone.name === selectedTimeZone.name
      && timeZone.value === selectedTimeZone.value);
  } else {
    matchedTimeZone = true;
  }

  if (matchedTimeZone) {
    return (
      <div className={wrapperClass}>
        <FlightSelect
          disabled={disabled}
          selected={selectedTimeZone}
          width={width}
          options={timeZoneList}
          label={label}
          hasIcon={false}
          handleOptionClick={handleTimeZoneChange}
          hasError={hasError}
          errorMessage={errorMessage}
          searchRegex={timeZoneRegex}
        />
      </div>
    );
  }
  return null;
};

FlightTimeZone.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  selectedTimeZone: PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
  handleTimeZoneChange: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  timeZoneList: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })),
  label: PropTypes.string,
  timeZoneRegex: PropTypes.string,
};

FlightTimeZone.defaultProps = {
  className: '',
  disabled: false,
  selectedTimeZone: null,
  timeZoneList: tz,
  width: 'unset',
  hasError: false,
  errorMessage: '',
  label: 'Pick a time zone',
  timeZoneRegex: '^\\(GMT[+-]([0-9]|1[0-9]|2[0-4]):[0-5][0-9]\\) ',
};

export default FlightTimeZone;
