import React from 'react';
import PropTypes from 'prop-types';
import './FlightToggleSwitch.scss';
import { EVENT_KEYS } from '../constants';

const DEFAULT_CLASS = 'flight-toggle-switch';

const FlightToggleSwitch = (props) => {
  const {
    disabled, label, checked, className, onChange,
  } = props;

  const handleClick = () => {
    if (disabled) return;
    if (onChange) {
      onChange(!checked);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === EVENT_KEYS.ENTER) {
      handleClick();
    }
  };

  let wrapperClass = `${DEFAULT_CLASS}-wrapper`;
  wrapperClass += disabled ? ` ${wrapperClass}--disabled` : '';
  wrapperClass += className ? ` ${className}` : '';

  return (
    <div className={wrapperClass}>
      <div
        role="button"
        className={DEFAULT_CLASS}
        tabIndex={disabled ? -1 : 0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
      >
        <label
          className={`${DEFAULT_CLASS}__switch`}
          htmlFor="toggle-switch"
        >
          <input
            className={`${DEFAULT_CLASS}__switch__input`}
            type="checkbox"
            checked={checked}
            readOnly
          />
          <span className={`${DEFAULT_CLASS}__switch__slide-bar`} />
          <div className={`${DEFAULT_CLASS}__switch__slider`} />
        </label>
        <span className={`${DEFAULT_CLASS}__span`}>{label}</span>
      </div>
    </div>

  );
};

FlightToggleSwitch.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

FlightToggleSwitch.defaultProps = {
  label: '',
  checked: false,
  className: '',
  disabled: false,
  onChange: () => undefined,
};

export default FlightToggleSwitch;
