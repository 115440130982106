import React from 'react';
import PropTypes from 'prop-types';
import FlightSelect from '../flight-select/FlightSelect';
import './FlightTime.scss';
import timeIntervalGen from '../utils/timeIntervalGen';

const DEFAULT_CLASS = 'flight-time';

const FlightTime = (props) => {
  const {
    className, disabled, selectedTime, handleTimeChange,
    width, interval, errorMessage, hasError, label,
  } = props;

  const timeStampArray = timeIntervalGen(interval);
  let matchedTimeStamp;

  if (selectedTime !== null) {
    matchedTimeStamp = timeStampArray
      .find((time) => time.key.toString() === selectedTime.key.toString()
      && time.name === selectedTime.name && time.value === selectedTime.value);
  } else {
    matchedTimeStamp = true;
  }

  let wrapperClass = DEFAULT_CLASS;
  wrapperClass += className ? ` ${className}` : '';

  if (matchedTimeStamp) {
    return (
      <div className={wrapperClass}>
        <FlightSelect
          disabled={disabled}
          selected={selectedTime}
          width={width}
          options={timeStampArray}
          label={label}
          hasIcon={false}
          handleOptionClick={handleTimeChange}
          hasError={hasError}
          errorMessage={errorMessage}
        />
      </div>
    );
  }
  return null;
};

FlightTime.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  selectedTime: PropTypes.shape({
    key: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
  handleTimeChange: PropTypes.func.isRequired,
  interval: PropTypes.number,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  label: PropTypes.string,
};

FlightTime.defaultProps = {
  className: '',
  disabled: false,
  selectedTime: null,
  width: 'unset',
  interval: 15,
  hasError: false,
  errorMessage: '',
  label: 'Pick a time',
};

export default FlightTime;
